
import { defineComponent } from "vue";
import SectionCard from "@/components/common/SectionCard.vue";

export default defineComponent({
  name: "MainPage",
  components: {
    SectionCard,
  },
  data: () => ({
    isActivationModalVisible: false,
    isLoading: false,
  }),
  methods: {
    toOrdersPage() {
      this.$router.push({ name: "main.orders" });
    },
    toUsersPage() {
      this.$router.push({ name: "main.clients" });
    },
  },
});
